<template lang="pug">
include ../../../configs/template
b-card(header-tag="header")
  div.seafarerInfoList
    div.w-100
      +data-info('translationAgency', 'sailorDocument.name')
    div.w-100
      b {{ $t('language') }}:
      span(v-for="lang in sailorDocument.languages" :key="lang").mr-1 {{ getDirectoryObject({ id: lang, value: 'languages' })[labelName] }};
    div.w-100
      TableV2(
        :headers="headers"
        :items="translationAgencyPricePrices"
        :pageCount="translationAgencyPricePrices.length"
        :querySearch="loadData"
        :isLoading="isLoading"
        isHiddenTableCount
        isHiddenPageCount
        isHiddenTableSearch
        showExpand
        componentEdit="TranslateAgencyPriceForm"
        :iconExpandedOpen="iconExpandedOpen").w-100.pa-0
        TranslateAgencyPriceAdd(slot="AddBlock" actionType="add")
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    TranslateAgencyPriceAdd: () => import('./PriceForm.vue')
  },
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      headers: [
        { value: 'date_start', text: this.$t('dateEffective'), sortable: false },
        { value: 'date_end', text: this.$t('dateTermination'), sortable: false },
        { value: 'price', text: this.$t('firstForm'), sortable: false },
        { value: 'unit', text: this.$t('secondForm'), sortable: false },
        { value: 'data-table-expand', sortable: false }
      ],
      iconExpandedOpen: {
        name: 'mdi-pen',
        color: 'blue',
        tooltip: 'tooltip.edit'
      }
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      translationAgencyPricePrices: state => state.backoffice.translationAgencyPricePrices,
      isLoading: state => state.backoffice.isLoading
    }),
    documentID () { return this.$route.params.id }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions(['getTranslationAgencyPrices']),

    loadData (params = {}) {
      const query = Object.assign(params, { translation_company: this.documentID })
      this.getTranslationAgencyPrices(query)
    }
  }
}
</script>
